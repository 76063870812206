<template>
	<div>
		<app-back-header></app-back-header>
		<v-row no-gutters dense class="my-card mb-8 d-flex flex-column">
			<div class="pa-0">
				<v-row no-gutters dense class="d-flex flex-grow-0">
					<v-col cols="5" class="pa-0">
						<p class="my-heading">
							Lists
							<span class="heading-sf16">of {{ titleName }}</span>
						</p>
					</v-col>
					<v-col cols="6" class="pa-0 filter-search">
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							dense
							color="#CACEDA"
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="1" class="export-icon">
						<div
								v-on:click="listExport()"
								style="cursor: pointer;"
							>
								<v-tooltip left color="#757575">
									<template v-slot:activator="{ on }">
										<img
											v-on="on"
											:src="$store.state.icons.icons['file-export']"
											style="height:24px;filter: invert(61%) sepia(10%) saturate(1008%) hue-rotate(178deg) brightness(84%) contrast(84%);"
											alt
										/>
									</template>
									<span>Export list detail</span>
								</v-tooltip>
							</div>
					</v-col>
				</v-row>
			</div>
			<v-data-table
				:items="listData"
				:page.sync="page"
				:headers="header"
				:items-per-page="itemsPerPage"
				:search="search"
				hide-default-footer
				mobile-breakpoint="1080"
				no-data-text="No record found"
			>
				<template v-slot:item.category_id="{ item }">
					<div class="py-3">
						<img
							v-if="item.category_id === 1"
							:src="$store.state.icons.icons['Lumber']"
							style="height:24px;"
							alt
						/>
						<img
							v-if="item.category_id === 2"
							:src="$store.state.icons.icons['Electric']"
							style="height:24px;"
							alt
						/>
						<img
							v-if="item.category_id === 3"
							:src="$store.state.icons.icons['Plumbing']"
							style="height:24px;"
							alt
						/>
						<img
							v-if="item.category_id === 4"
							:src="$store.state.icons.icons['Masonry']"
							style="height:24px;"
							alt
						/>
						<img
							v-if="item.category_id === 5"
							:src="$store.state.icons.icons['ToolBoxes']"
							style="height:24px;"
							alt
						/>
					</div>
				</template>
				<template v-slot:item.total_bids="{ item }">
					<div v-if="item.total_bids != '0'">
						<v-tooltip left color="#757575">
							<template v-slot:activator="{ on }">
								<div
									v-on="on"
									class="my-pointer"
									v-on:click="showBids(item.list_id)"
								>{{ item.total_bids }} / {{ item.active_bids }}</div>
							</template>
							<span>Get bids of list</span>
						</v-tooltip>
					</div>
					<div
						v-else
						>{{ item.total_bids }} / {{ item.active_bids }}</div>
				</template>
				<template v-slot:item.status="{ item }">
					<div
						v-if="item.status === 'BA'"
						class="text-tag-green text-tag-btn d-flex align-center justify-center my-2"
					>Bid Accepted</div>
					<div
						v-else-if="item.status === 'E'"
						class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
					>Expired</div>
					<div
						v-else-if="item.status === 'A'"
						class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
					>Active</div>
				</template>
				<template v-slot:item.action="{ item }">
					<v-tooltip left color="#757575">
						<template v-slot:activator="{ on }">
							<div v-on="on" class="my-pointer" v-on:click="listView(item)">
								<img :src="$store.state.icons.icons['detail-view']" style="height:26px" class="table-icon" />
							</div>
						</template>
						<span>View list detail</span>
					</v-tooltip>
				</template>
			</v-data-table>

			<div class="d-flex align-center justify-center my-tabel-footer py-2">
				<v-pagination
					v-model="page"
					color="#2974FF"
					:total-visible="7"
					:length="getTotalPage(this.$store.state.list.list_data)"
				></v-pagination>
			</div>
		</v-row>
		<app-list-view ref="listview"></app-list-view>
	</div>
</template>

<script>
import { ExportToCsv } from "export-to-csv";
export default {
	components: {
		AppListView: () => import("../common/ListView"),
		AppBackHeader: () => import("./Header")
	},

	data() {
		return {
			page: 1,
			name: "",
			itemsPerPage: 10,
			search: "",
			list_data: [],
			header: [
				{ text: "Category", value: "category_id" },
				{ text: "List Name", value: "list_name" },
				{ text: "Address", value: "address" },
				{ text: "Bids/Active", value: "total_bids" },
				{ text: "Status", value: "status" },
				{
					text: "Action",
					value: "action",
					sortable: false,
					align: "center"
				}
			]
		};
	},

	computed: {
		listData() {
			let data = this.$store.getters.getList;
			if(data.length === 0){
				let id = null;
				id = parseInt(this.$route.params.id);
				if(id !== null){
					this.$store.dispatch("listOfCont",id);
					data = this.$store.getters.getList; 
				}
			}
			return data;
		},

		titleName() {
			let id = null;
			id = parseInt(this.$route.params.id);
			if (id !== null) {
				let data = JSON.parse(
					JSON.stringify(this.$store.getters.getContractorById(id))
				);
				if(data === true) {
					this.$store.dispatch("contList");
					data = JSON.parse(
						JSON.stringify(this.$store.getters.getContractorById(id))
					);
				}
				this.name = data.fullname;
			}
			return this.name;
		}
	},

	methods: {
		getTotalPage(data) {
			return Math.ceil(data.length / this.itemsPerPage);
		},

		listView(item) {
			this.$refs.listview.openListView(item.list_id);
		},

		showBids(id) {
			this.$store.dispatch('listBids', id);
			this.$router.push({ path: '/contractors/bids/' + id });
		},
		// async listExport() {
		// 	let data = await listExport();
		// 	let url = data.data;
		// 	window.open(url, "_blank");
		// },
		listExport() {
			this.list_data = this.$store.getters.getList;
    const options = {
        filename:"Contractor list data",
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Contractor list data",
        useTextFile: false,
        useBom: true,
        // useKeysAsHeaders: true,
        headers: [
          "Category_Id",
          "List_Name",
          "Address",
          "Bids/Active",
          "Status",
        ],
      };

      const csvExporter = new ExportToCsv(options);

      let list_ob = [];
      this.list_data.forEach((data) => {
        let list = {};
        list.category_id = data.category_id;
        list.list_name = data.list_name;
        list.address = data.address;
        list.total_bids = data.total_bids;
        if (data.status === "BA") {
          list.status = "Bid Accepted";
        } else if (data.status === "E") {
          list.status = "Expired";
        } else if (data.status === "A") {
          list.status = "Active";
        }
        list_ob.push(list);
      });
      csvExporter;
      csvExporter.generateCsv(list_ob);
    },
	}
};
</script>
